import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
    path: '/',
    redirect: '/validatePhone'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/reLogin',
    name: 'reLogin',
    component: () => import('@/views/login/reLogin.vue')
  },
  {
    path: '/checkBind',
    name: 'checkBind',
    component: () => import('@/views/login/checkBind.vue')
  },
  {
    path: '/validatePhone',
    name: 'validatePhone',
    component: () => import('@/views/login/validatePhone.vue')
  },
  {
    path: '/findStudent',
    name: 'findStudent',
    component: () => import('@/views/login/findStudent.vue')
  },
  {
    path: '/index',
    name: 'index',
    mate:{
      title:'首页'
    },
    component: () => import('@/views/index/index.vue')
  },
  {
    path: '/courseTable',
    name: 'courseTable',
    component: () => import('@/views/askForLeave/index.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('@/views/personal/index.vue'),
  },
  {
    path: '/personalInfo',
    name: 'personalInfo',
    component: () => import('@/views/personal/personalInfo.vue')
  },
  {
    path: '/myBindStudent',
    name: 'myBindStudent',
    meta:{title:'关联学员'},
    component: () => import('@/views/personal/myBindStudent.vue'),
  },
  {
    path: '/validatePhonePersonal',
    name: 'validatePhonePersonal',
    meta:{title:'验证手机号'},
    component: () => import('@/views/personal/validatePhonePersonal.vue'),
  },
  {
    path: '/findStudentPersonal',
    name: 'findStudentPersonal',
    meta:{title:'查找学员'},
    component: () => import('@/views/personal/findStudentPersonal.vue'),
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import('@/views/schedule/index.vue')
  },
  {
    path: '/callTheRollDetails',
    name: 'callTheRollDetails',
    component: () => import('@/views/schedule/callTheRollDetails.vue')
  },
  {
    path: '/commentTeacher',
    name: 'commentTeacher',
    component: () => import('@/views/commentTeacher/index.vue')
  },
  {
    path: '/writeComment',
    name: 'writeComment',
    component: () => import('@/views/commentTeacher/writeComment.vue')
  },
  {
    path: '/commentDetail',
    name: 'commentDetail',
    component: () => import('@/views/commentTeacher/commentDetail.vue')
  },
  {
    path: '/studentCommentDetail',
    name: 'studentCommentDetail',
    component: () => import('@/views/index/studentCommentDetail.vue')
  },
  {
    path: '/askForLeave',
    name: 'askForLeave',
    component: () => import('@/views/askForLeave/index.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/askForLeave/detail.vue')
  },
  {
    path: '/requestList',
    name: 'requestList',
    component: () => import('@/views/askForLeave/requestList.vue')
  },
  {
    path: '/courseList',
    name: 'courseList',
    component: () => import('@/views/courseList/index.vue')
  },
  {
    path: '/selectTime',
    name: 'selectTime',
    component: () => import('@/views/askForLeave/selectTime.vue')
  },
  {
    path: '/courseListDetail',
    name: 'courseListDetail',
    component: () => import('@/views/courseList/detail.vue')
  },
  {
    path: '/giftMall',
    name: 'giftMall',
    component: () => import('@/views/giftMall/index.vue'),
  
  },
  {
    path: '/giftMallDetail',
    name: 'giftMallDetail',
    component: () => import('@/views/giftMall/detail.vue'),
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('@/views/giftMall/exchange.vue')
  },
  {
    path: '/exchangeSuccess',
    name: 'exchangeSuccess',
    component: () => import('@/views/giftMall/exchangeSuccess.vue')
  },
  {
    path: '/exchangeDetial',
    name: 'exchangeDetial',
    component: () => import('@/views/giftMall/exchangeDetial.vue')
  },
  {
    path: '/pointsDetails',
    name: 'pointsDetails',
    component: () => import('@/views/giftMall/pointsDetails.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
