import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/assets/css/base.less'
import Vant from 'vant';
import "vant/lib/index.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTableCells,faCalendarDays,faRectangleList,faDatabase} from "@fortawesome/free-solid-svg-icons";
library.add(faTableCells,faCalendarDays,faRectangleList,faDatabase);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import navBar from "@/components/navBar.vue";
import tabBar from "@/components/tabBar.vue";


const app = createApp(App);
app.use(store).use(router).use(Vant).component('font-awesome-icon', FontAwesomeIcon).component('navBar',navBar).component('tabBar',tabBar).mount("#app");