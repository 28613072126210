<template>
  <div>
    <van-tabbar route style="border-top: 1px solid #f1f1f1">
      <van-tabbar-item replace to="/index" icon="home-o">服务</van-tabbar-item>
      <van-tabbar-item replace to="/personal" icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script setup>
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
let router = useRouter();

let active = ref(0);

</script>
