<template>
  <div style="height: 46px;position: relative;z-index: 9999;top: 0px;">
    <van-nav-bar fixed class="navbar" @click-left="onClickLeft">
      <template #title>
        <span>{{ title }}</span>
      </template>
      <template #left>
        <van-icon name="arrow-left" size="18" />
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    let { name } = toRefs(props);
    let title = ref(name);
    const onClickLeft = () => history.back();
    return {
      title,
      onClickLeft,
    };
  },
};
</script>

<style lang="less" scoped>
.navbar {
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
}
</style>
